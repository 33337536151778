<template>
    <Popover v-model:open="open">
        <PopoverTrigger :as-child="true">
            <slot
                :clearable="clearable"
                :disabled="disabled"
                :error="error"
                :selected-options="selectedOptions"
                name="trigger"
            >
                <button
                    :id="id || name"
                    :class="cn(
                        'flex h-9 items-stretch justify-between rounded shadow-sm bg-white ring-1 ring-gray-200 text-sm whitespace-nowrap transition',
                        'placeholder:text-gray-500',
                        'focus:outline-none focus:ring-2 focus:ring-tenant-500 focus:shadow-tenant-500/50',
                        // 'focus:outline-none focus:ring-2 focus:ring-gray-950 focus:shadow-gray-950/50',
                        'dark:ring-gray-800 dark:bg-gray-950',
                        'dark:placeholder:text-gray-400',
                        'dark:focus:ring-gray-300',
                        'disabled:cursor-not-allowed disabled:opacity-50 disabled:shadow-none',
                        '[&>span]:truncate [&>span]:min-w-0',
                        error && 'ring-red-300 focus:ring-red-500',
                        props.class ?? ''
                    )"
                    :disabled="disabled"
                    :tabindex="disabled ? '-1' : tabindex"
                >
                    <slot name="before" />

                    <div :class="cn('flex flex-1 items-center justify-between px-3 py-2', triggerClass)">
                        <slot name="trigger-content">
                            <span v-if="!selectedOptionsLabel" class="text-gray-400">
                                {{ placeholder }}
                            </span>
                            <slot v-else name="trigger-text">
                                <span class="truncate">
                                    {{ selectedOptionsLabel }}
                                </span>
                            </slot>
                        </slot>

                        <div class="ml-2 flex shrink-0 items-center gap-2">
                            <AlertCircleIcon
                                v-if="error"
                                v-tippy="error"
                                class="size-4 text-red-500"
                            />
                            <button v-if="showClearButton" tabindex="-1" @click.stop="onClear">
                                <XIcon class="size-4 text-gray-500" />
                            </button>

                            <AspectSpinner v-if="loading" class="size-4 text-gray-500" />
                            <ChevronDownIcon v-else class="size-4 text-gray-500" />
                        </div>
                    </div>

                    <slot name="after" />
                </button>
            </slot>
        </PopoverTrigger>
        <PopoverContent
            :align="align"
            :side="side"
            :auto-width="autoWidth"
            :class="cn(
                'p-0 min-w-40',
                contentClass,
            )"
        >
            <Command
                v-model:search-term="searchTerm"
                :filter-function="filterValues"
                @keyup.esc="open = false"
            >
                <CommandInput
                    v-if="opened"
                    :hidden="!searchable"
                    :loading="externalSearchLoading"
                    :placeholder="t('Search...')"
                />
                <CommandEmpty
                    v-if="showEmpty"
                    :search-term="searchTerm"
                    :class="searchable && 'border-t border-gray-200'"
                >
                    <slot name="empty">
                        {{ t('No options available') }}
                    </slot>
                </CommandEmpty>
                <CommandList
                    v-if="!waitingForSearch && !showEmpty"
                    :class="searchable && 'border-t border-gray-200'"
                >
                    <CommandGroup>
                        <CommandItem
                            v-for="option in sortedOptions"
                            :key="option.value"
                            :disabled="option.disabled"
                            :value="option[searchKey || 'label']"
                            @select="onSelect(option)"
                        >
                            <CheckIcon v-if="showCheckMark" :class="cn('mr-2 h-4 w-4 shrink-0', isSelected(option.value) ? 'opacity-100' : 'opacity-0')" />
                            <slot
                                :option="option"
                                :selected="isSelected(option.value)"
                                name="option"
                            >
                                <div>
                                    <div class="truncate">
                                        {{ option.label }}
                                    </div>
                                    <div v-if="option.subLabel" class="truncate text-xs text-gray-500">
                                        {{ option.subLabel }}
                                    </div>
                                </div>
                            </slot>
                        </CommandItem>
                    </CommandGroup>
                </CommandList>
            </Command>
        </PopoverContent>
    </Popover>
</template>

<script lang="ts" setup>
    import { ref, watch, nextTick } from 'vue';
    import { directive as vTippy } from 'vue-tippy';
    import { XIcon, ChevronDownIcon, CheckIcon, AlertCircleIcon } from 'lucide-vue-next';

    import cn from '@aspect/shared/utils/cn.ts';
    import { t } from '@aspect/shared/plugins/i18n.ts';
    import { useSelect } from '@aspect/shared/composables/use-select.ts';

    import AspectSpinner from '@aspect/shared/components/aspect-spinner.vue';

    import { Popover, PopoverContent, PopoverTrigger } from '@aspect/shared/components/ui/popover';
    import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from '@aspect/shared/components/ui/command';

    import type { SelectProps } from '@aspect/shared/composables/use-select.ts';


    // PROPS & EMIT
    const props = withDefaults(defineProps<SelectProps>(), {
        autoWidth: false,
        autoSelect: false,
        multiple: false,
        sorted: true,
        align: 'start',
        side: 'bottom',
        clearable: false,
        disabled: false,
        placeholder: ' ',
        searchable: false,
        showCheckMark: true,
        externalSearch: false,
        externalSearchLoading: false,
        loading: false,
        contentClass: '',
        searchKey: '',
    });

    const modelValue = defineModel<any>({ required: true });
    const searchTerm = defineModel<string>('searchTerm', { default: '' });
    const open = ref(false);
    const opened = ref(false);

    watch(open, (value) => {
        if (value) {
            nextTick(() => {
                opened.value = value;
            });
        } else {
            // This is the lenght of the close animation
            setTimeout(() => {
                opened.value = value;
            }, 200);
        }
    });

    const {
        sortedOptions,
        selectedOptions,
        selectedOptionsLabel,
        waitingForSearch,
        showEmpty,
        showClearButton,

        onSelect,
        onClear,
        isSelected,
        filterValues,
    } = useSelect(props, modelValue, searchTerm, open);
</script>
