<template>
    <section
        ref="element"
        class="scroll-mt-32 self-center"
    >
        <figure class="flex justify-center">
            <div class="flex size-32 items-center justify-center rounded-full bg-gray-950">
                <CheckIcon class="size-16 text-white" />
            </div>
        </figure>

        <div class="mb-9 mt-6 text-center text-sm">
            <h2 class="text-lg" v-text="t('Reservation Successful')" />
            <h3 class="font-medium">
                #{{ reservationCode }}
            </h3>
        </div>

        <div class="flex flex-col gap-6 px-3">
            <div class="text-center text-sm">
                <p v-if="customerEmail" class="font-medium">
                    {{ t('A confirmation email has been sent to :email', { email: customerEmail }) }}
                </p>
                <p>
                    {{ t('You can now close this page.') }}
                </p>
            </div>
        </div>
    </section>
</template>

<script lang="ts" setup>
    import { onMounted, ref } from 'vue';
    import { CheckIcon } from '@heroicons/vue/24/solid';

    import { t } from '@aspect/shared/plugins/i18n.ts';

    defineProps<{
        reservationCode: string;
        customerEmail: string;
    }>();


    // SCROLL
    const element = ref<HTMLElement>();

    onMounted(() => {
        element.value?.scrollIntoView({ block: 'center', behavior: 'smooth' });
    });
</script>
