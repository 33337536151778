<template>
    <Switch
        :id="id ?? name"
        :class="cn(
            'relative inline-flex h-5 w-10 shrink-0 rounded-full border shadow-inner',
            'focus-visible:ring-2 focus-visible:ring-offset-2', focusVisibleColor,
            'transition-colors duration-200 ease-in-out',
            'border-gray-200 bg-gray-50',
            disabled && 'cursor-not-allowed shadow-none bg-gray-100',
            modelValue && activeColor,
        )"
        :name="name"
        :model-value="modelValue"
        :disabled="disabled"
        @update:model-value="onUpdate"
    >
        <span
            :class="cn(
                'pointer-events-none m-px inline-block size-4 rounded-full ring-0 bg-white shadow',
                'transition duration-200 ease-in-out',
                'translate-x-0',
                modelValue && 'translate-x-5',
                disabled && 'shadow-sm bg-gray-200',
                modelValue && disabled && 'bg-gray-300',
            )"
            aria-hidden="true"
        />
    </Switch>
</template>

<!-- Inline MDN -->
<!-- name attribute is the check group/category name. -->
<!-- value attribute is the check option name. -->
<!-- id attribute is usually the same as value and there to make Label's for work -->

<script lang="ts" setup>
    import { computed } from 'vue';
    import { Switch } from '@headlessui/vue';

    import cn from '@aspect/shared/utils/cn.ts';

    const props = withDefaults(defineProps<{
        modelValue: boolean,
        disabled?: boolean,
        disabledReason?: string,
        name?: string,
        id?: string,
        color?: 'blue' | 'black',
    }>(), {
        disabled: false,
        color: 'blue',
    });

    const emit = defineEmits(['update:modelValue']);

    const focusVisibleColor = computed(() => {
        return {
            blue: 'focus-visible:ring-blue-500',
            black: 'focus-visible:ring-gray-500',
        }[props.color];
    });

    const activeColor = computed(() => {
        if (props.disabled) {
            return 'border-gray-300 bg-gray-200';
        }

        return {
            blue: 'border-blue-600 bg-blue-500',
            black: 'border-black bg-gray-950',
        }[props.color];
    });

    function onUpdate(value: boolean) {
        if (props.disabled) {
            return;
        }

        emit('update:modelValue', value);
    }
</script>
