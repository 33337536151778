<template>
    <input
        v-bind="$attrs"
        v-model="model"
        v-tippy="{ content: disabled && disabledReason ? disabledReason : '', trigger: 'mouseenter' }"
        :class="cn(
            'size-4 shadow-sm border-gray-300',
            'focus:ring-0 focus:ring-offset-0 focus-visible:ring-2 focus-visible:ring-offset-2',
            !disabled && color === 'blue' && 'text-blue-500 focus-visible:ring-blue-500',
            !disabled && color === 'tenant' && 'text-tenant-500 focus-visible:ring-tenant-500',
            !disabled && color === 'black' && 'text-gray-950 focus-visible:ring-gray-950',
            !disabled && error && 'border-red-500 text-red-500 focus-visible:ring-red-500',
            disabled && 'cursor-not-allowed bg-gray-100 text-gray-400',
            $attrs.class ?? '',
        )"
        type="radio"
        @click="onClick"
    >
</template>

<!-- Inline MDN -->
<!-- name attribute is the radio group/category name. -->
<!-- value attribute is the radio option name. -->
<!-- id attribute is usually the same as value and there to make Label's for work -->

<script lang="ts" setup>
    import { directive as vTippy } from 'vue-tippy';
    import cn from '@aspect/shared/utils/cn.ts';

    defineOptions({
        inheritAttrs: true,
    });

    const props = withDefaults(defineProps<{
        disabled?: boolean;
        disabledReason?: string;
        readonly?: boolean;
        error?: string;
        color?: 'blue' | 'tenant' | 'black';
    }>(), {
        disabled: false,
        readonly: false,
        color: 'blue',
    });

    const model = defineModel<any>();

    function onClick(event: MouseEvent) {
        if (props.disabled || props.readonly) {
            event.preventDefault();
        }
    }
</script>
