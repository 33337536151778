<template>
    <VueDatePicker
        v-if="inline"
        ref="picker"
        :class="['!block rounded shadow-sm', props.class]"
        :auto-apply="true"
        :clearable="false"
        :config="{ closeOnAutoApply: closeOnAutoApply }"
        :disabled="disabled"
        :enable-time-picker="['date-time', 'time'].includes(mode)"
        :flow="flow"
        :inline="true"
        :locale="currentLocaleTag"
        :model-value="formattedModelValue"
        :month-change-on-scroll="false"
        :month-picker="mode === 'month'"
        :partial-flow="true"
        :range="isRange && { partialRange: false }"
        :time-picker="mode === 'time'"
        :year-picker="mode === 'year'"
        :week-picker="mode === 'week'"
        :disable-year-select="disableYear"
        six-weeks="center"
        v-bind="$attrs"
        @update:model-value="onUpdate"
    />

    <Popover v-else v-model:open="open">
        <PopoverTrigger :as-child="true">
            <slot>
                <AspectInput
                    :disabled="disabled"
                    :error="error"
                    :model-value="formattedForDisplay"
                    :name="name"
                    :class="cn('cursor-pointer text-left', disabled && 'cursor-not-allowed')"
                >
                    <template #after>
                        <div v-if="clearable && !isEmpty" class="absolute inset-y-0 right-0 flex items-center px-4">
                            <button tabindex="-1" @click.stop="onUpdate(null)">
                                <XIcon class="size-4 text-gray-500" />
                            </button>
                        </div>
                    </template>
                </AspectInput>
            </slot>
        </PopoverTrigger>

        <PopoverContent class="w-auto p-0 shadow-none ring-0">
            <VueDatePicker
                ref="picker"
                :class="['!block rounded shadow-lg', props.class]"
                :auto-apply="true"
                :clearable="false"
                :config="{ closeOnAutoApply: closeOnAutoApply }"
                :disabled="disabled"
                :enable-time-picker="['date-time', 'time'].includes(mode)"
                :flow="flow"
                :inline="true"
                :locale="currentLocaleTag"
                :model-value="formattedModelValue"
                :month-change-on-scroll="false"
                :month-picker="mode === 'month'"
                :week-picker="mode === 'week'"
                :partial-flow="true"
                :range="isRange && { partialRange: false }"
                :time-picker="mode === 'time'"
                :year-picker="mode === 'year'"
                :disable-year-select="disableYear"
                six-weeks="center"
                v-bind="$attrs"
                @update:model-value="onUpdate"
            />
        </PopoverContent>
    </Popover>
</template>

<script lang="ts" setup>
    // TODO: handle error
    import { ref, computed } from 'vue';
    import { XIcon } from 'lucide-vue-next';
    import VueDatePicker from '@vuepic/vue-datepicker';

    import { date, format } from '@aspect/shared/utils/date.ts';
    import { currentLocaleTag } from '@aspect/shared/plugins/i18n.ts';
    import cn from '@aspect/shared/utils/cn.ts';

    import AspectInput from '@aspect/shared/components/aspect-input.vue';

    import { Popover, PopoverContent, PopoverTrigger } from '@aspect/shared/components/ui/popover';

    import type { Dayjs } from 'dayjs';
    import type { ModelValue } from '@vuepic/vue-datepicker';

    type Value = Dayjs | string | null;

    interface RangeValue {
        start: Value;
        end: Value;
    }

    defineOptions({
        inheritAttrs: false,
    });

    const props = withDefaults(
        defineProps<{
            modelValue: Value | RangeValue;
            name?: string;
            mode?: 'date-time' | 'date' | 'time' | 'month' | 'year' | 'week';
            error?: string;
            range?: boolean;
            closeOnAutoApply?: boolean;
            disabled?: boolean;
            clearable?: boolean;
            inline?: boolean;
            class?: string;
            multiCalendars?: boolean;
            disableYear?: boolean;
        }>(),
        {
            mode: 'date-time',
            range: false,
            closeOnAutoApply: false,
            disabled: false,
            clearable: false,
            inline: false,
        },
    );
    const emit = defineEmits(['update:modelValue']);

    const open = ref(false);

    const isRange = computed(() => props.range && props.mode === 'date');

    const flow = computed(() => {
        return (
            {
                'date-time': ['calendar', 'time'],
                'date': ['calendar'],
                'time': ['time'],
            }[props.mode] || []
        );
    });

    const formattedModelValue = computed<ModelValue>(() => {
        switch (props.mode) {
            case 'date-time':
            case 'date':
                if (isRange.value) {
                    if (!(props.modelValue as RangeValue).start || !(props.modelValue as RangeValue).end) {
                        return null;
                    }

                    return [
                        date((props.modelValue as RangeValue).start).toDate(),
                        date((props.modelValue as RangeValue).end).toDate(),
                    ];
                } else if (props.modelValue) {
                    return date(props.modelValue as string).toDate();
                } else {
                    return null;
                }
            case 'time':
                if (!props.modelValue) {
                    return null;
                }

                return {
                    hours: parseInt((props.modelValue as string).split(':')[0]),
                    minutes: parseInt((props.modelValue as string).split(':')[1]),
                };
            case 'month':
                return {
                    year: parseInt((props.modelValue as string).split('-')[0]),
                    month: parseInt((props.modelValue as string).split('-')[1]) - 1,
                };
            case 'year':
                return props.modelValue as string;
            default:
                return '';
        }
    });

    const isEmpty = computed(() => {
        if (isRange.value) {
            return !props.modelValue || !(props.modelValue as RangeValue).start && !(props.modelValue as RangeValue).end;
        }

        return !props.modelValue;
    });

    const formattedForDisplay = computed(() => {
        if (!formattedModelValue.value) {
            return null;
        }

        if (isRange.value) {
            return `${format(formattedModelValue.value[0], 'D MMM YYYY')} - ${format(formattedModelValue.value[1], 'D MMM YYYY')}`;
        }

        const dateFormat = props.disableYear ? 'D MMM' : 'D MMM YYYY';

        return (
            {
                'date-time': format(props.modelValue as Value, 'D MMM YYYY, HH:mm'),
                'date': format(props.modelValue as Value, dateFormat),
                'time': props.modelValue as string,
                'month': format(props.modelValue as Value, 'MMMM YYYY'),
                'year': format(props.modelValue as Value, 'YYYY'),
            }[props.mode] || ''
        );
    });

    function formatValue(value) {
        if (!value) {
            return null;
        }

        const dateFormat = props.disableYear ? 'MM-DD' : 'YYYY-MM-DD';

        return {
            'date-time': () => format(value),
            'date': () => format(value, dateFormat),
            'time': () => `${value.hours.toString().padStart(2, '0')}:${value.minutes.toString().padStart(2, '0')}`,
            'month': () => `${value.year}-${(value.month + 1).toString().padStart(2, '0')}`,
            'year': () => value,
        }[props.mode]() || '';
    }

    function onUpdate(value) {
        if (props.mode === 'date') {
            open.value = false;
        }

        if (isRange.value) {
            emit('update:modelValue', {
                start: formatValue(value ? value[0] : null),
                end: formatValue(value ? value[1] : null),
            });
        } else {
            emit('update:modelValue', formatValue(value));
        }
    }
</script>

<style src="../../../node_modules/@vuepic/vue-datepicker/dist/main.css" />
<style src="../css/vendor/vue-datepicker.css" />
