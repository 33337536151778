import { computed } from 'vue';
import { orderBy } from 'lodash-es';
import { useLaravelVapor } from '@aspect/shared/composables/use-laravel-vapor.ts';

import type { Ref } from 'vue';
import type { EntryCustomerData, SlotData } from '@aspect/shared/types/generated';

export interface Entry {
    id: string | null;
    code: string;
    picture?: string | null;
    name?: string | null
    customer?: EntryCustomerData | null,
}

export function useSlotEntries(slot: Ref<SlotData>) {
    const vapor = useLaravelVapor();

    return {
        entries: computed<Entry[]>(() => {
            const defaultPersonPicture = vapor.asset('/images/default-person.png');

            const existingEntries = (slot.value.entries || [])
                .filter((entry) => entry.entryableType === 'ticket' && entry.isValid)
                .map((entry) => ({
                    id: entry.id,
                    code: entry.code,
                    name: entry.name || null,
                    customer: entry.customer,
                    picture: defaultPersonPicture,
                }));

            const missingEntriesCount = Math.max(0, (slot.value.capacity || 0) - existingEntries.length);
            const missingEntries: Entry[] = Array.from({ length: missingEntriesCount }, (_, index) => ({
                id: null,
                code: `${slot.value.id}-${missingEntriesCount - index}`,
            }));

            const entries = [...existingEntries, ...missingEntries];

            return orderBy(entries, ['customer.name', 'name', 'id'], ['asc', 'asc', 'asc']);
        }),
    };
}
