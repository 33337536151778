<template>
    <table class="text-right text-sm">
        <tbody>
            <tr>
                <td>{{ t('Subtotal:') }}</td>
                <td class="pl-1">
                    <AspectData type="currency">
                        {{ formatCurrency(subtotal, currency) }}
                    </AspectData>
                </td>
            </tr>
            <tr v-if="discountAmount > 0">
                <td>{{ t('Discount:') }}</td>
                <td class="pl-1">
                    <AspectData type="currency">
                        {{ formatCurrency(discountAmount, currency) }}
                    </AspectData>
                </td>
            </tr>
            <tr v-for="(tax, index) in filteredTaxes" :key="tax.name">
                <td :class="index === filteredTaxes.length - 1 && !transactionFee && !giftCardsTotal && 'pb-1.5'">
                    {{ t(':tax:', { tax: getFormattedRate(taxRates, tax) }) }}
                </td>
                <td :class="index === filteredTaxes.length - 1 && !transactionFee && !giftCardsTotal && 'pb-1.5'" class="pl-1">
                    <AspectData type="currency">
                        {{ formatCurrency(tax.amount, currency) }}
                    </AspectData>
                </td>
            </tr>
            <tr v-if="giftCardsTotal">
                <td :class="!transactionFee && 'pb-1.5'">
                    <!--{{ t('Gift Card:') }}-->
                    {{ t('Enhanced Promo Code:') }}
                </td>
                <td :class="['pl-1', !transactionFee && 'pb-1.5']">
                    <AspectData type="currency">
                        -{{ formatCurrency(giftCardsTotal, currency) }}
                    </AspectData>
                </td>
            </tr>
            <tr v-if="transactionFee">
                <td class="pb-1.5">
                    {{ t('Transaction Fee:') }}
                </td>
                <td class="pb-1.5 pl-1">
                    <AspectData type="currency">
                        {{ formatCurrency(transactionFee, currency) }}
                    </AspectData>
                </td>
            </tr>
            <tr class="border-t border-gray-200">
                <td class="pt-1.5">
                    {{ t('Total:') }}
                </td>
                <td class="pl-1 pt-1.5">
                    <AspectData type="currency">
                        {{ formatCurrency(total, currency) }}
                    </AspectData>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script lang="ts" setup>
    import { computed } from 'vue';
    import { t } from '@aspect/shared/plugins/i18n.ts';
    import { formatCurrency } from '@aspect/shared/utils/number.ts';
    import { getFormattedRate } from '@aspect/shared/utils/money.ts';

    import AspectData from '@aspect/shared/components/aspect-data.vue';

    import type { Currency, TaxData, TaxRateData } from '@aspect/shared/types/generated';

    const props = defineProps<{
        currency: Currency,
        subtotal: number;
        discountAmount: number;
        discountedSubtotal: number;
        transactionFee: number | null;
        total: number;
        taxes: TaxData[];
        taxRates: TaxRateData[];
        giftCardsTotal?: number | null;
    }>();

    const activeTaxRates = computed(() => {
        return props.taxRates.filter(rate => !rate.archivedAt);
    });

    const filteredTaxes = computed(() => {
        return props.taxes.filter(tax => {
            const isDefaultTaxRate = activeTaxRates.value.some(taxRate => {
                return taxRate.defaultRate && taxRate.code === tax.name && taxRate.rate === tax.rate;
            });

            return isDefaultTaxRate || tax.amount > 0;
        });
    });
</script>
