<template>
    <div ref="squareContiner" />
    <AspectAlert v-if="hasSquareError" type="error">
        {{ t('We are currently experiencing issues with our payment provider.') }}
        {{ t('We apologize for any inconvenience this may cause and kindly request that you try again at a later time.') }}
    </AspectAlert>
    <div v-if="!cardAttached" class="absolute inset-0 flex items-center justify-center">
        <AspectSpinner class="size-8" />
    </div>
</template>

<script lang="ts" setup>
    import { ref, watch, onBeforeMount, onBeforeUnmount } from 'vue';
    import { t } from '@aspect/shared/plugins/i18n.ts';
    import { useSquare } from '@aspect/ticket-office/composables/use-square.ts';

    import AspectSpinner from '@aspect/shared/components/aspect-spinner.vue';
    import AspectAlert from '@aspect/shared/components/aspect-alert.vue';

    const hasError = defineModel<boolean>('hasError', { required: true });

    const {
        attachCard,
        detachCard,
        hasSquareError,
        cardAttached,
        init: initSquare,
        destroy: destroySquare
    } = useSquare();

    const squareContiner = ref<HTMLElement>();

    watch(squareContiner, async () => {
        if (squareContiner.value) {
            attachCard(squareContiner.value);
        } else {
            detachCard();
        }
    });

    watch(hasSquareError, (value) => {
        hasError.value = value;
    });

    onBeforeMount(() => {
        initSquare();
    });

    onBeforeUnmount(() => {
        destroySquare();
    });
</script>

<style>
    .sq-card-wrapper .sq-card-message {
        @apply hidden !mb-0 !mt-4;
    }

    .sq-card-wrapper .sq-card-message.sq-card-message-error {
        @apply block;
    }
</style>
