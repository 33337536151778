<template>
    <div class="sticky inset-x-0 top-0 z-20 flex h-14 items-stretch justify-between rounded-t border-b border-gray-200 bg-white">
        <div :class="cn('flex gap-3 items-center text-center justify-center px-6 sm:text-left', props.class)">
            <slot />
        </div>
        <div class="flex items-stretch justify-end">
            <slot name="actions" />

            <DialogClose :as-child="true">
                <AspectButtonAttached class="rounded-tr border-l">
                    <AspectIcon class="size-5 text-gray-600" name="delete-1" />
                </AspectButtonAttached>
            </DialogClose>
        </div>
    </div>
</template>

<script lang="ts" setup>
    import cn from '@aspect/shared/utils/cn.ts';

    import AspectIcon from '@aspect/shared/components/aspect-icon.vue';
    import AspectButtonAttached from '@aspect/shared/components/aspect-button-attached.vue';

    import DialogClose from './dialog-close.vue';

    interface DialogHeaderProps {
        class?: string
    }

    const props = defineProps<DialogHeaderProps>();
</script>
