<template>
    <component
        :is="disabled || loading ? 'button' : tag"
        v-tippy="{ content: disabled ? disabledReason : information, trigger: 'mouseenter' }"
        :class="cn(
            'shrink-0 overflow-hidden relative isolate-auto flex items-center justify-center text-center bg-gray-100/70 bg-backdrop-blur-lg',
            'group transition-all', // Group is used for overlays
            !disabled && 'focus-visible:ring-2 focus-visible:z-10',
            !disabled && 'hover:isolate hover:shadow-lg',
            !disabled && 'active:shadow-sm',
            !disabled && colorClassName,
            sizeClassName,
            disabled && 'transition-all cursor-not-allowed text-gray-500 focus-visible:ring-gray-400 bg-white/90 backdrop-blur-md active:scale-100',
            props.class,
        )"
        @mouseenter="hovering = true"
        @mouseleave="hovering = false"
        @click="onClick"
    >
        <div
            :class="cn(
                'flex flex-1 items-center justify-center gap-2 whitespace-nowrap',
                truncate && 'line-clamp-1 break-all',
                loading && 'opacity-0',
                contentClassName,
            )"
        >
            <AspectIcon
                v-if="icon"
                :class="cn('shrink-0', iconClassName)"
                :name="icon"
            />

            <slot />

            <div
                :class="[
                    'absolute inset-0 isolate z-10 size-full select-none opacity-0 transition-all duration-500',
                    disabled && 'bg-pattern-danger bg-repeat opacity-100',
                ]"
            />

            <div
                v-if="!disabled"
                :class="[
                    'absolute inset-0 isolate z-10 size-full select-none transition-all',
                    'group-hover:opacity-0 group-active:opacity-100 group-active:shadow-inner-md',
                    skeumorphicClassName,
                ]"
            />
        </div>

        <AspectSpinner
            v-if="loading"
            :light="lightSpinner"
            class="absolute size-5"
        />

        <AspectIndicator
            :active="indicator"
            :attached-on="indicatorAttachedOn"
            :color="indicatorColor"
        />
    </component>
</template>

<script lang="ts" setup>
    import { computed, ref } from 'vue';
    import { directive as vTippy } from 'vue-tippy';

    import type { Component } from 'vue';

    import cn from '@aspect/shared/utils/cn.ts';

    import AspectIcon from '@aspect/shared/components/aspect-icon.vue';
    import AspectSpinner from '@aspect/shared/components/aspect-spinner.vue';
    import AspectIndicator from '@aspect/shared/components/aspect-indicator.vue';

    const props = withDefaults(defineProps<{
        tag?: string | Component;
        disabled?: boolean;
        disabledReason?: string;
        information?: string;
        size?: 'sm' | 'md' | 'lg' | 'xl';
        color?: 'blue' | 'red' | 'green' | 'amber' | 'yellow' | 'white' | 'black' | 'transparent';
        colorHoverOnly?: boolean;
        icon?: string;
        loading?: boolean;
        indicator?: boolean;
        indicatorColor?: 'blue' | 'yellow';
        indicatorAttachedOn?: 'top' | 'bottom' | 'left' | 'right';
        contentClassName?: string;
        iconClassName?: string;
        class?: any;
        truncate?: boolean;
    }>(), {
        tag: 'button',
        size: 'md',
        disabled: false,
        colorHoverOnly: true,
        color: 'white',
        loading: false,
        indicator: false,
        indicatorAttachedOn: 'bottom',
        iconClassName: 'size-5',
        truncate: false,
        information: '',
    });
    const emit = defineEmits(['click']);

    const hovering = ref(false);

    const lightSpinner = computed(() => {
        if (props.disabled || props.color === 'white') {
            return false;
        }

        if (props.colorHoverOnly) {
            return hovering.value;
        }

        return true;
    });

    const sizeClassName = computed(() => {
        return {
            sm: 'p-2',
            md: 'p-4',
            lg: 'p-6',
            xl: 'p-8',
        }[props.size];
    });

    const skeumorphicClassName = computed(() => {
        return {
            sm: 'bg-texture-skeu',
            md: 'bg-texture-skeu',
            lg: 'bg-texture-skeu-prominent',
            xl: 'bg-texture-skeu-prominent',
        }[props.size];
    });

    const colorClassName = computed(() => {
        // Assumes background is white since colour only applied when hover.
        if (props.colorHoverOnly) {
            return {
                white: 'bg-white/80 backdrop-blur-lg ring-blue-500 hover:border-gray-200 hover:bg-white/100',
                black: 'bg-white/80 backdrop-blur-lg ring-blue-500 hover:border-black hover:bg-gray-900 hover:text-gray-100',

                blue: 'bg-white/80 backdrop-blur-lg ring-blue-500 hover:bg-blue-500 hover:border-blue-500 hover:shadow-blue-500/25 hover:text-blue-50',
                red: 'bg-white/80 backdrop-blur-lg ring-red-500 hover:bg-red-500 hover:border-red-500 hover:shadow-red-500/25 hover:text-red-50',
                green: 'bg-white/80 backdrop-blur-lg ring-green-500 hover:bg-green-500 hover:border-green-500 hover:shadow-green-500/25 hover:text-green-50',
                yellow: 'bg-white/80 backdrop-blur-lg ring-yellow-500 hover:bg-yellow-500 hover:border-yellow-500 hover:shadow-yellow-500/25 hover:text-yellow-50',
                amber: 'bg-white/80 backdrop-blur-lg ring-amber-500 hover:bg-amber-500 hover:border-amber-500 hover:shadow-amber-500/25 hover:text-amber-50',
            }[props.color];
        }

        return {
            white: 'bg-white ring-blue-500 text-gray-800',
            black: 'bg-gray-800 border-black ring-gray-500 text-gray-100 hover:bg-black',
            transparent: 'bg-transparent', // Manage colours manually

            blue: 'bg-blue-500 !border-blue-500 ring-blue-500 text-blue-50 hover:bg-blue-600 hover:border-blue-600 hover:shadow-blue-500/25',
            red: 'bg-red-500 !border-red-500 ring-red-500 text-red-50 hover:bg-red-600 hover:border-red-600 hover:shadow-red-500/25',
            green: 'bg-green-500 !border-green-500 ring-green-500 text-green-50 hover:bg-green-600 hover:border-green-600 hover:shadow-green-500/25',
            yellow: 'bg-yellow-500 !border-yellow-500 ring-yellow-500 text-yellow-50 hover:bg-yellow-600 hover:border-yellow-600 hover:shadow-yellow-500/25',
            amber: 'bg-amber-500 !border-amber-500 ring-amber-500 text-amber-50 hover:bg-amber-600 hover:border-amber-600 hover:shadow-amber-500/25',
        }[props.color];
    });

    function onClick(event: PointerEvent) {
        if (props.disabled || props.loading) {
            event.stopPropagation();
            return;
        }

        emit('click', event);
    }
</script>
